import React, { useMemo, memo, useCallback, useContext, useEffect, useState } from 'react';
import type { FC } from 'react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';
import idx from 'idx';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { PrimaryDropdownButton } from '@atlaskit/atlassian-navigation';
import mapLocaleToPrsLocale from '@atlaskit/prs-locale-mapper';

import type { Flag as PeopleMenuFlag } from '@atlassian/people-teams/types';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import { withMandatoryPeopleMenuConfiguration } from '@atlassian/people-teams-configuration-client/people-menu';
import type { WithMandatoryPeopleMenuConfigurationProps } from '@atlassian/people-teams-configuration-client/people-menu';
import { useTeamsPermissionsService } from '@atlassian/teams-common/use-teams-permissions';

import { SSRMouseEventWrapper, SSR_NAV_PEOPLE_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useSessionData } from '@confluence/session-data';
import type { FlagType } from '@confluence/flags';
import { FlagsStateContainer, handleShowingFlag } from '@confluence/flags';
import { LoadableAfterPaint } from '@confluence/loadable';
import { PEOPLE_DIRECTORY } from '@confluence/named-routes';
import {
	useRouteDataRef,
	useRouteActions,
	useRouteName,
	useTransitionId,
} from '@confluence/route-manager';
import { PEOPLE } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useRenotifyInviteeEligible } from '@confluence/onboarding-helpers/entry-points/hooks/useRenotifyInviteeEligible';
import { OnboardingCTANudge } from '@confluence/onboarding-nudge-tooltip';
import { AppNavigationContext } from '@confluence/app-navigation-context';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { CoreInvitesContext, CoreInvitesSource } from '@confluence/core-invites-provider';
import { TopNavItemNudgeWrappable } from '@confluence/onboarding-hover-nudge/entry-points/BorderNudge';
import { HOVER_TARGET } from '@confluence/onboarding-hover-nudge/entry-points/constants/HoverTarget';
import { SPAViewContext } from '@confluence/spa-view-context';
import { isLocaleEnglish } from '@confluence/onboarding-helpers/entry-points/isLocaleEnglish';
import { useOnboardingNewHomeTakeoverEnabled } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingNewHomeTakeover';
import { fg } from '@confluence/feature-gating';

import { PrimaryItemWrapper } from '../presentationalComponents';
import { OPEN_SITES_PERMISSION_CHECK_FLAG } from '../featureFlagKeys';
import { AppNavigationUnifiedQuery } from '../AppNavigationUnifiedQuery.graphql';
import { useTPAExperiment } from '../hooks/useTPAExperiment';

import type { PrimaryItemProps } from './PrimaryItem';
import { closeCurrentPopup, setCloseCurrentPopup } from './PrimaryDropdownItem';

const i18n = defineMessages({
	InviteTeammatesV2: {
		id: 'app-navigation.people.nudge.heading.v2',
		defaultMessage: 'Add people here',
		description: 'Invite teammates here',
	},
	InviteTeammatesNudgeV2: {
		id: 'app-navigation.people-nav-item-nudge-tooltip.v2',
		defaultMessage:
			'Invite teammates from the <b>People</b> menu, and you’ll accomplish great things together in no time.',
		description: 'nudge tooltip for people on top nav',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NudgeFixer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
});

const Placeholder = ({ remoteConfig }: WithMandatoryPeopleMenuConfigurationProps) => {
	return <PrimaryDropdownButton>{remoteConfig.navLabel}</PrimaryDropdownButton>;
};

const LoadingState = withMandatoryPeopleMenuConfiguration(Placeholder);

export const LazyPeopleMenu = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PeopleItemWrapper" */ './PeopleItemWrapper'))
			.PeopleItemWrapper,
	loading: () => <LoadingState />,
	_noPreloadWhenRenderingSPA: true,
});

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ './analyticsCallbacks'))
			.firePeopleClickedAnalytics,
);

const peopleMenuFlagAppearanceToConfluenceFlagType = (
	appearance: PeopleMenuFlag['appearance'],
): FlagType => {
	switch (appearance) {
		case 'normal':
		case 'success':
			return 'success-circle';
		case 'warning':
			return 'warning';
		case 'error':
			return 'error';
		case 'info':
			return 'info';
		default:
			return 'custom';
	}
};

const addFlagFn = (flags: FlagsStateContainer) => (flag: PeopleMenuFlag) => {
	const { title, description, appearance, actions } = flag;
	void flags.showFlag({
		type: peopleMenuFlagAppearanceToConfluenceFlagType(appearance),
		title,
		description,
		close: 'auto',
		actions,
	});
};

export const PeopleItemComponent: FC<PrimaryItemProps & WrappedComponentProps> = memo(
	({ testId, intl }) => {
		const routeDataRef = useRouteDataRef();
		const { push } = useRouteActions();
		const transitionId = useTransitionId();
		const { resetStickySearchRef } = useContext(AppNavigationContext);
		const { cloudId, userId, orgId, featureFlagClient, locale } = useSessionData();
		const { isSiteAdmin } = useContext(SPAViewContext);
		const { data, loading } = useQuery(AppNavigationUnifiedQuery);
		// this state is for People Menu
		const [isOpen, setIsOpen] = useState(false);
		const closePeopleMenu = useCallback(() => setIsOpen(false), [setIsOpen]);
		const openPeopleMenu = useCallback(() => setIsOpen(true), [setIsOpen]);
		const { isEnabled: isOnboardingNewHomeTakeoverEnabled } = useOnboardingNewHomeTakeoverEnabled();
		const resetStickySearch = useCallback(() => {
			resetStickySearchRef?.current?.();
		}, [resetStickySearchRef]);

		const onItemClick = useCallback(
			(_id: string, _type: 'people' | 'team') => {
				resetStickySearchRef?.current?.();
			},
			[resetStickySearchRef],
		);

		const { openCoreInvites } = useContext(CoreInvitesContext);
		const invitePeopleHandlers = useMemo(() => {
			return (
				openCoreInvites && {
					handleClick: () => openCoreInvites(CoreInvitesSource.PEOPLE_MENU),
				}
			);
		}, [openCoreInvites]);

		const enableOpenSitesPermissionCheck = featureFlagClient.getBooleanValue(
			OPEN_SITES_PERMISSION_CHECK_FLAG,
			{
				default: false,
			},
		);

		const renotifyInviteeEligible = useRenotifyInviteeEligible({
			skip: !isSiteAdmin || !isLocaleEnglish(locale),
		});

		const {
			permissions: { canCreateTeams, canViewTeams },
		} = useTeamsPermissionsService(
			{
				orgId,
				cloudId,
			},
			{
				enabled: enableOpenSitesPermissionCheck,
			},
		);

		const projectTeamAccessData = useTPAExperiment();

		useEffect(() => {
			// Close people-menu when navigating to different URL
			return () => closePeopleMenu();
			// do not add closePeopleMenu the dependency list.
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [transitionId]);

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const fireClickedAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

		const handleClickPeopleMenuDropdown = useCallback(() => {
			// sync open/close state with other menus
			if (!isOpen) {
				// now people-menu is going to open so we need to close other nav popups
				closeCurrentPopup('people menu opened');
				// so other menus open can close people-menu when they open.
				setCloseCurrentPopup(closePeopleMenu);
			}
			void fireClickedAnalytics();
		}, [isOpen, fireClickedAnalytics, closePeopleMenu]);

		const isOnPeopleRouteArgs = {
			selector: (routeName: string | undefined) => {
				if (!routeName) {
					return false;
				}
				return routeName === PEOPLE_DIRECTORY.name;
			},
		};
		const isHighlighted = useRouteName(isOnPeopleRouteArgs);

		if (loading) {
			return <LoadingState />;
		}

		const user = {
			id: idx(data, (_) => _.user.id),
			fullName: idx(data, (_) => _.user.displayName),
			avatarUrl: idx(data, (_) => _.user.photos[0].value),
		};

		return (
			<OnboardingCTANudge
				position="bottom-start"
				wrapper={NudgeFixer}
				heading={<FormattedMessage {...i18n.InviteTeammatesV2} />}
				content={
					<FormattedMessage
						{...i18n.InviteTeammatesNudgeV2}
						values={{
							b: (...chunks) => <b>{chunks}</b>,
						}}
					/>
				}
				source="onboardingNudge"
				actionSubjectId={PEOPLE}
				renderChildrenWhileLoading
				width={280}
				dataVC="app-navigation-teams-onboarding-button"
			>
				<Subscribe to={[FlagsStateContainer]}>
					{(flags: FlagsStateContainer) => (
						<SSRMouseEventWrapper metricName={SSR_NAV_PEOPLE_BUTTON_METRIC}>
							<TopNavItemNudgeWrappable
								target={HOVER_TARGET.INVITE}
								shouldWrap={isOnboardingNewHomeTakeoverEnabled}
							>
								<PrimaryItemWrapper data-vc="app-navigation-teams-onboarding-button-inner">
									{/*// this won't have any effect on those using the old people-menu except it will still cache*/}
									{/*// the remotely configured result for when they are rolled out to the new version*/}
									<PeopleAndTeamsConfigurationProvider
										product="confluence"
										locale={mapLocaleToPrsLocale(intl.locale)}
										teamPermissions={{
											canCreateTeams,
											canViewTeams,
										}}
									>
										<LazyPeopleMenu
											cloudId={cloudId}
											userId={userId || ''}
											product="confluence"
											orgId={orgId}
											testId={testId}
											isHighlighted={isHighlighted}
											onClick={handleClickPeopleMenuDropdown}
											// allow PeopleMenu to do transitions to in-product People Directory
											pushRoute={push}
											// @ts-ignore FIXME: `handleShowingFlag` can't handle `appearance === undefined`, which seems like could be invoked by `LazyPeopleMenu`
											addFlag={
												fg('people_menu_success_circle')
													? addFlagFn(flags)
													: handleShowingFlag(flags)
											}
											isOpen={isOpen}
											onClose={closePeopleMenu}
											onOpen={openPeopleMenu}
											onClickViewPeopleDirectoryLink={resetStickySearch}
											onClickCreateNewTeam={resetStickySearch}
											onClickedItem={onItemClick}
											teamCreateDialogProps={{
												currentUser: { ...user },
												enableMembershipSettingsChoice: true,
												projectTeamAccess: projectTeamAccessData,
											}}
											invitePeopleHandlers={invitePeopleHandlers}
											renotifyExperimentProps={renotifyInviteeEligible}
											shouldRenderToParent
										/>
									</PeopleAndTeamsConfigurationProvider>
								</PrimaryItemWrapper>
							</TopNavItemNudgeWrappable>
						</SSRMouseEventWrapper>
					)}
				</Subscribe>
			</OnboardingCTANudge>
		);
	},
);

export const PeopleItem = injectIntl(PeopleItemComponent);
