import gql from 'graphql-tag';

import { SpacesListSpaceFragment } from './SpacesListSpaceFragment.fragment';

export const StarredSpacesQuery = gql`
	query StarredSpacesQuery($includeAlias: Boolean = false) {
		spaces(favourite: true, first: 99, status: "current") {
			nodes {
				...SpacesListSpaceFragment
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const RecentSpacesQuery = gql`
	query RecentSpacesQuery($includeAlias: Boolean = false) {
		recentSpaces {
			...SpacesListSpaceFragment
		}
	}
	${SpacesListSpaceFragment}
`;

export const RecentSpacesWithCurrentSpaceQuery = gql`
	query RecentSpacesWithCurrentSpaceQuery(
		$selectedSpaceKey: String
		$includeAlias: Boolean = false
	) {
		space(key: $selectedSpaceKey) {
			...SpacesListSpaceFragment
			currentUser {
				isFavourited
			}
		}
		recentSpaces {
			...SpacesListSpaceFragment
		}
	}
	${SpacesListSpaceFragment}
`;

export const MyVisitedSpacesQuery = gql`
	query MyVisitedSpacesQuery($includeAlias: Boolean = false) {
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpacesListSpaceFragment
				}
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const MyVisitedSpacesWithCurrentSpaceQuery = gql`
	query MyVisitedSpacesWithCurrentSpaceQuery(
		$selectedSpaceKey: String
		$includeAlias: Boolean = false
	) {
		space(key: $selectedSpaceKey) {
			...SpacesListSpaceFragment
			currentUser {
				isFavourited
			}
		}
		myVisitedSpaces(limit: 26) {
			nodes {
				spaces {
					...SpacesListSpaceFragment
				}
			}
		}
	}
	${SpacesListSpaceFragment}
`;

export const AnonymousSpacesQuery = gql`
	query AnonymousSpacesQuery($includeAlias: Boolean = false) {
		spaces(first: 10) {
			nodes {
				...SpacesListSpaceFragment
			}
		}
	}
	${SpacesListSpaceFragment}
`;
