import React, { memo, useCallback, useContext, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import { Box, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import {
	SSRMouseEventWrapper,
	SSR_NAV_COMPANY_HUB_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { ExperienceTrackerContext } from '@confluence/experience-tracker';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { FullPageLoadingScreen } from '@confluence/full-page-loading-screen';
import { COMPANY_HUB } from '@confluence/named-routes';
import { usePageSpaceKey } from '@confluence/page-context';
import {
	FrontCoverStateEnum,
	isCompanyHubSpaceKey,
} from '@confluence/route-manager/entry-points/companyHubUtils';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useIsTryBeforeYouBuyState } from '@confluence/company-hub-utils/entry-points/useIsTryBeforeYouBuyState';
import { useCompanyHubPremiumGate } from '@confluence/company-hub-utils/entry-points/useCompanyHubPremiumGate';

import type { onClick } from './companyHubItemCallback';
import { PrimaryItem } from './PrimaryItem';

const i18n = defineMessages({
	companyHubLink: {
		id: 'app-navigation.company-hub.link',
		description: 'name of the fallback name to the company hub page',
		defaultMessage: 'Company hub',
	},
});

/* Revisit styles when transition from site title to space name is done https://hello.jira.atlassian.cloud/browse/CTE-3671 */
const companyHubItemStyles = xcss({
	display: 'none',
	[media.above.md]: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
const highlightStyles = (isHighlightedBorderColor: any, isHighlighted: boolean) =>
	xcss({
		borderTopColor: 'transparent',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		borderBottomColor: isHighlighted ? `${isHighlightedBorderColor}` : 'transparent',
		borderBlockWidth: 'border.width.indicator',
		borderBlockStyle: 'solid',
	});

const lazyOnClick = async (...args: Parameters<typeof onClick>) => {
	const { onClick } = await import(
		/* webpackChunkName: "loadable-companyHubItemCallback" */ './companyHubItemCallback'
	);
	await onClick(...args);
};

export const CompanyHubItem = withFlags(
	memo(({ flags }: WithFlagsProps) => {
		const { frontCoverState, loading, companyHubName } = useContext(SPAViewContext);
		const { match, push } = useContext(RoutesContext);
		const { theme } = useContext(AppNavigationContext);
		const [spaceKey] = usePageSpaceKey();
		const [isLoading, setIsLoading] = useState(false);

		const mayBeFirstTimeVisit = frontCoverState === FrontCoverStateEnum.UNSET;
		const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
			skip: !mayBeFirstTimeVisit,
		});
		const isTryBeforeYouBuyState = useIsTryBeforeYouBuyState();
		const shouldShowCompanyHubPremiumUpsell = shouldShowPremiumUpsell && !isTryBeforeYouBuyState;

		// The link/namedRoute of this PrimaryItem not being ready means that the
		// space doesn't exist yet and is to be created first:
		const namedRoute =
			mayBeFirstTimeVisit && !shouldShowCompanyHubPremiumUpsell ? undefined : COMPANY_HUB;

		const { createAnalyticsEvent } = useAnalyticsEvents();
		const experienceTracker = useContext(ExperienceTrackerContext);

		const onClick = useCallback(async () => {
			const isFirstTimeVisit = !namedRoute;
			const args: Parameters<typeof lazyOnClick> = [
				createAnalyticsEvent,
				experienceTracker,
				flags,
				isFirstTimeVisit,
				push,
				/* routeName */ match?.name,
			];
			if (isFirstTimeVisit) {
				// There's no route to navigate to, it needs to be created first. This
				// may take a noticeably long time so a loading state visible to the
				// user is necessary:
				setIsLoading(true);
				try {
					await lazyOnClick(...args);
				} catch {
					// Not much can be done about a JS bundle failing to load even if the
					// failure were reported.
				} finally {
					setIsLoading(false);
				}
			} else {
				// There's a route to navigate to already, what's left is processing not
				// visible to the user. So there's no need for a loading state visible
				// to the user here:
				void lazyOnClick(...args);
			}
		}, [createAnalyticsEvent, experienceTracker, flags, match, namedRoute, push]);

		if (loading || !frontCoverState) {
			return null;
		}

		const label = companyHubName || <FormattedMessage {...i18n.companyHubLink} />;

		const isHighlighted = match?.name === COMPANY_HUB.name || isCompanyHubSpaceKey(spaceKey);
		const isHighlightedBorderColor = theme?.mode.primaryButton.selected.borderColor;

		return (
			<Box
				// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
				xcss={[companyHubItemStyles, highlightStyles(isHighlightedBorderColor, isHighlighted)]}
				{...(isHighlighted && { testId: 'highlightContainer' })}
			>
				<SSRMouseEventWrapper metricName={SSR_NAV_COMPANY_HUB_BUTTON_METRIC}>
					<PrimaryItem
						testId="app-navigation-company-hub-item"
						isHighlighted={false}
						label={label}
						namedRoute={namedRoute}
						onClick={onClick}
					/>
					{isLoading ? <FullPageLoadingScreen /> : null}
				</SSRMouseEventWrapper>
			</Box>
		);
	}),
);
