import React, { useContext, useCallback } from 'react';
import type { FC } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { mergeRefs } from 'use-callback-ref';

import { useIsReducedMotion } from '@atlaskit/motion';
import Button from '@atlaskit/button/custom-theme-button';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import type { TriggerProps } from '@atlaskit/popup';
import { SpotlightPulse } from '@atlaskit/onboarding';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import {
	useSmartLinkEmbedOnboardingActions,
	useGlobalCreateSpotlightPulseState,
} from '@confluence/embed-onboarding';
import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';

import { usePreloadCreationMenuData } from '../usePreloadCreationMenuData';

import { useNoticeableCreateButtonExperiment } from './useNoticeableCreateButtonExperiment';
import {
	RESPONSIVE_CONTENT_WIDTH_THRESHOLD,
	IconWrapper,
	CreateContentButtonWrapper,
} from './CreateButtonStyles';

const CreateButton = LoadableAfterPaint<any>({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-TopNav" */ '@atlassian/navigation-system/top-nav'))
			.CreateButton,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreateContentButtonTextWrapper = styled.span({
	display: 'inline',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@media (max-width: ${RESPONSIVE_CONTENT_WIDTH_THRESHOLD}px)`]: {
		display: 'none',
	},
});

const i18n = defineMessages({
	create: {
		id: 'create-blank-fabric-page.create.content.popover.button',
		defaultMessage: 'Create',
		description: 'button to display popover',
	},
	createContentLabel: {
		id: 'create-blank-fabric-page.create.content.aria.label',
		defaultMessage: 'Create content',
		description: 'The aria label for create button',
	},
});

export const getCreateButtonTheme = (createButtonTheme) => (current, props) => {
	const { buttonStyles, spinnerStyles } = current(props);
	return {
		buttonStyles: {
			...buttonStyles,
			...createButtonTheme.default,
			margin: 0,
			fontWeight: 500,
			':hover': createButtonTheme.hover,
			':focus': createButtonTheme.focus,
			// :active doesn't work in FF, because we do a
			// e.preventDefault() on mouse down in Button.
			// '&&' is required to add more CSS specificity
			// && it not a valid CSSObject property
			// @ts-ignore
			'&&': {
				...(props.state === 'active' && createButtonTheme.active),
			},
		},
		spinnerStyles,
	};
};

export const GlobalCreateContentButton: FC<{
	triggerProps: TriggerProps;
	onClick: () => void;
	isWhiteboardSpotlightEnabled?: boolean;
}> = ({ triggerProps, onClick, isWhiteboardSpotlightEnabled }) => {
	const { ref } = triggerProps;
	const intl = useIntl();
	const { theme, createContentPopoverButtonRef } = useContext(AppNavigationContext);
	const preloadCreationMenuData = usePreloadCreationMenuData();
	const noticeableCreateExperiment = useNoticeableCreateButtonExperiment();

	const isReducedMotion = useIsReducedMotion();

	// Smart Link Embeds Onboarding
	const { toggleGlobalCreateSpotlightPulse } = useSmartLinkEmbedOnboardingActions();
	const isSmartLinkEmbedOnboardingPulseActive = useGlobalCreateSpotlightPulseState();

	const handleClick = useCallback(() => {
		toggleGlobalCreateSpotlightPulse(false);
		onClick();
	}, [onClick, toggleGlobalCreateSpotlightPulse]);

	const buttonTheme = theme?.mode?.create ? getCreateButtonTheme(theme.mode.create) : undefined;

	const createButton = fg('confluence_nav_4') ? (
		<CreateButton onClick={handleClick}>
			<FormattedMessage {...i18n.create} />
		</CreateButton>
	) : (
		<Button
			appearance="primary"
			theme={buttonTheme}
			onClick={handleClick}
			iconBefore={
				<IconWrapper experiment={noticeableCreateExperiment} isReducedMotion={isReducedMotion}>
					<EditorAddIcon size="medium" label="" data-vc="global-content-create-button" />
				</IconWrapper>
			}
			testId="create-content-popover-button"
			{...triggerProps}
			aria-label={intl.formatMessage(i18n.createContentLabel)}
			ref={
				createContentPopoverButtonRef
					? mergeRefs([triggerProps.ref, createContentPopoverButtonRef])
					: triggerProps.ref
			}
		>
			<CreateContentButtonTextWrapper>
				<FormattedMessage {...i18n.create} />
			</CreateContentButtonTextWrapper>
		</Button>
	);

	const getCreateButton = () => {
		if (isSmartLinkEmbedOnboardingPulseActive && !isWhiteboardSpotlightEnabled) {
			return (
				<SpotlightPulse pulse={!isReducedMotion} radius={3}>
					{createButton}
				</SpotlightPulse>
			);
		} else {
			return createButton;
		}
	};

	return (
		<CreateContentButtonWrapper
			data-vc="create-content-button-wrapper"
			ref={ref}
			onMouseEnter={preloadCreationMenuData}
			onFocus={preloadCreationMenuData}
			experiment={noticeableCreateExperiment}
			isReducedMotion={isReducedMotion}
			isNav4Enabled={fg('confluence_nav_4')}
		>
			{getCreateButton()}
		</CreateContentButtonWrapper>
	);
};
