import FeatureGates from '@atlaskit/feature-gate-js-client';

import { useCompanyHubPremiumGate } from './useCompanyHubPremiumGate';

export const useIsTryBeforeYouBuyState = ({ skip = false } = {}) => {
	const shouldShowPremiumUpsell = useCompanyHubPremiumGate({
		skip,
	});

	const isTryBeforeYouBuyEnabled = FeatureGates.getExperimentValue(
		'confluence_frontend_company_hub_tbyb',
		'isTBYBEnabled',
		false,
	);
	return shouldShowPremiumUpsell && isTryBeforeYouBuyEnabled;
};
