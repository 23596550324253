import FeatureGates from '@atlaskit/feature-gate-js-client';
import { useIsReducedMotion } from '@atlaskit/motion';

export const useNoticeableCreateButtonExperiment = () => {
	const isReducedMotion = useIsReducedMotion();
	return FeatureGates.getExperimentValue('cc_more_noticeable_create_button', 'design', 'control', {
		// Reduced motion users will not see any animation, so they should not be included in the experiment group
		fireExperimentExposure: !isReducedMotion,
	});
};
